/** @format */

@import "../../styleConstants/colors";
@import "src/styleConstants/typography";

.input {
  padding: 10px 22px;

  border: 2px solid $primary_300;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  background-color: var(--bg-color);

  color: $neutral_900;

  &::placeholder {
    color: $neutral_500;
  }

  @extend %font_title_large;
}