/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/typography";
@import "src/styleConstants/variables";

.create_story_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  --bg-color: #{$primary_50};
}

.container {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.stepper_container {
  transition: 0.4s $animationFunction;

  height: 32px;
  opacity: 1;
  transform: translateY(0);

  &[data-is-show="false"] {
    height: 0;
    opacity: 0;
    transform: translateY(-64px);
  }
}

.form {
  padding: 24px;
  width: calc(100svw - 50px);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 40px;
}

.description {
  margin: 0;
  padding: 0;

  text-align: center;
  color: $neutral_900;
  @extend %font_title_large;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @include mobile {
    flex-direction: column;
    align-items: stretch;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.loader {
  @keyframes spin-loader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  width: 64px;
  height: 64px;

  box-sizing: border-box;
  border-style: solid;
  border-color: $primary_700;
  border-top-color: transparent !important;
  border-radius: 100%;
  animation: spin-loader 0.7s infinite linear;
}

.title {
  margin: 0;
  padding: 0;

  text-align: center;
  color: $neutral_900;
  @extend %font_display_large;
}

.checkbox_blocks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.checkbox_block_image_with_text {
  width: 196px;
  margin: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  img {
    pointer-events: none;
    user-select: none;
  }

  p {
    margin: 0;
    padding: 0;
    text-align: center;

    @extend %font_title_large;
  }
}

