/** @format */

@import '../createStoryPage.module';

.scan_page_form {
  @extend .form;
  max-width: 732px;
}

.upload_document_form {
  @extend .form;
  max-width: 732px;
}

.paste_website_link_form {
  @extend .form;
  max-width: 732px;
}

.paste_website_text_form {
  @extend .form;
  max-width: 732px;
}

.textarea {
  width: 100%;
  //min-width: 732px;
  //max-width: 100%;

  height: 96px;
  min-height: 96px;

  padding: 12px 24px;

  resize: vertical;

  border: 2px solid $primary_300;
  border-radius: 4px;
  background-color: $primary_50;
  box-sizing: border-box;

  @extend %font_title_large;

  color: $neutral_900;

  ::placeholder {
    color: $neutral_400;
  }
}

.select_container {
  width: 240px;

  display: flex;
  flex-direction: column;
  justify-content: start;
}

.select_label {
  margin: 0;
  padding: 0;

  color: $neutral_900;
  @extend %font_label_large;
}