/** @format */

@import "../../styleConstants/colors";
@import "src/styleConstants/typography";

.button {
  margin: 0;
  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border: none;
  border-radius: 300px;

  &:not(:disabled) {
    cursor: pointer;
  }

  @extend %font_title_medium;

  &[data-variant="primary"] {
    color: $base_white;
    background-color: $primary_700;

    &:disabled {
      background-color: $neutral_400;
    }
  }

  &[data-variant="outline"] {
    color: $primary_700;
    background-color: var(--bg-color);
    border: 1px solid $primary_700;
    box-sizing: border-box;

    &:disabled {
      border-color: $neutral_400;
      color: $neutral_400;
    }
  }

  &[data-variant="text"] {
    color: $primary_700;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
  }
}

.link_button {
  @extend .button;
  text-decoration: none;
}
