/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/typography";
@import "src/styleConstants/variables";

$animationDuration: 0.4s;

.stepper_container {
  width: max-content;
  max-width: max-content;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}

.stepper_dot {
  width: 32px;
  min-width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $primary_200;
  border-radius: 100%;
  border: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  // Number

  &:before {
    position: absolute;
    content: attr(data-step);

    color: $primary_600;
    @extend %font_label_large;

    transition: $animationDuration $animationFunction;
  }

  &[data-is-prev="true"]:before {
    opacity: 0;
  }

  &[data-is-next="true"]:before {
    color: $primary_400;
  }

  // Check mark

  &:after {
    content: "";
    width: 20px;
    height: 20px;

    background: url("../CheckboxBlock/checkbox_checked.svg") center;
    background-size: 20px;

    opacity: 0;

    transition: $animationDuration $animationFunction;
  }

  &[data-is-prev="true"]:after {
    opacity: 1;
  }
}

.divider {
  width: 32px;
  min-width: 32px;
  height: 4px;

  background-color: $primary_200;
}