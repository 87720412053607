/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/shadow";
@import "src/styleConstants/typography";

.checkbox_block {
  position: relative;
  width: fit-content;
  //height: fit-content;

  display: block;

  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid $primary_100;

  &[aria-disabled="false"] {
    color: $neutral_600;

    &:not(:has(input.target_input:checked)) {
      background-color: $primary_100;
      @extend %shadow-lg;
    }

    &:has(input.target_input:checked) {
      background-color: $primary_50;
      border-color: $primary_600;
    }
  }

  &[aria-disabled="true"] {
    color: $neutral_400;
    background-color: $primary_100;

    img {
      filter: grayscale(100%) brightness(110%);
    }
  }

  @extend %font_title_large;
}

input.target_input {
  display: none;
}

.fake_checkbox {
  position: absolute;

  top: 8px;
  width: 20px;
  height: 20px;

  &:dir(ltr) {
    left: 8px;
  }

  &:dir(rtl) {
    right: 8px;
  }

  transition: background-image 0.1s ease-in-out;
}

input.target_input:not(:checked)+.fake_checkbox {

  background-image: url("./checkbox_unchecked.svg");
}

input.target_input:checked+.fake_checkbox {

  background-image: url("./checkbox_checked.svg");
}

input.target_input:disabled+.fake_checkbox {

  background-image: url("./checkbox_disabled.svg");
}