/** @format */

$primary_900: #4C1D95;
$primary_800: #5B21B6;
$primary_700: #6D28D9;
$primary_600: #7C3AED;
$primary_500: #8B5CF6;
$primary_400: #A78BFA;
$primary_300: #C4B5FD;
$primary_200: #DDD6FE;
$primary_100: #EDE9FE;
$primary_50 : #F5F3FF;

$neutral_900: #0F172A;
$neutral_800: #1E293B;
$neutral_700: #334155;
$neutral_600: #475569;
$neutral_500: #64748B;
$neutral_400: #94A3B8;
$neutral_300: #CBD5E1;
$neutral_200: #E2E8F0;
$neutral_100: #F3F4F6;
$neutral_50 : #F8FAFC;

$rose_500: #F43F5E;
$rose_400: #F87171;
$rose_50 : #FFE4E6;

$base_white: #FFF;