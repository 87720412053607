/** @format */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700;900&display=swap');

@mixin font_display_large {
  /* display/L */
  font-family: Lato, sans-serif;
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 112.281% */
  letter-spacing: -0.25px;
}

%font_display_large {
  @include font_display_large;
}

%font_display_medium {
  /* display/M */
  font-family: Lato, sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 115.556% */
}

%font_display_small {
  /* display/S */
  font-family: Lato, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 122.222% */
}


%font_headline_large {
  /* headline/L */
  font-family: Lato, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
}

%font_headline_medium {
  /* headline/M */
  font-family: Lato, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
}

%font_headline_small {
  /* headline/S */
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}


%font_title_large {
  /* title/L */
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 127.273% */
}

%font_title_medium {
  /* title/M */
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

%font_title_small {
  /* title/S */
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}


%font_label_large {
  /* label/L */
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}

%font_label_medium {
  /* label/M */
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.5px;
}


%font_body_large {
  /* body/L */
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

%font_body_medium {
  /* body/M */
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

%font_body_small {
  /* body/S */
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

//

%font_header_ai {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 80% */
}

%font_homepage_description {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 32px */
}