/** @format */

@import 'src/styleConstants/colors';
@import 'src/styleConstants/typography';
@import 'src/styleConstants/variables';

.block {

  overflow: hidden;
  border: 2px dotted $neutral_300;
  border-radius: 4px;
}

.block_separator {
  padding: 16px;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 24px;

  @include mobile {
    flex-direction: column;
  }
}


.block_actions {
  min-width: 30%;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  border-radius: 4px;
  background-color: $neutral_100;

  @include mobile {
    margin: 0;
  }
}

.action_container {
  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.block_header {
  padding: 8px 16px;

  background-color: $neutral_100;

  h4, h5 {
    padding: 0;
    margin: 0;

    color: $neutral_600;
    @extend %font_body_large;
  }

  h4 {
    font-weight: bold;
  }
}

.block_content_image {
  width: 225px;
  height: 225px;
  margin: auto;

  @include mobile {
    width: 100%;
  }
}

.block_content_text {
  width: 62%;
  padding: 16px;

  @extend %font_body_large;
  color: $neutral_600;

  @include mobile {
    width: 100%;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  ol {
    padding-left: 20px;
  }

  table {
    border-collapse: collapse;
  }

  th {
    padding: 4px;
    text-align: start;
  }

  td {
    padding: 4px;
    border: 1px dotted $neutral_300;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  @extend %font_title_medium;
  color: $neutral_600;
}

.block_edit_button {
  padding: 0;

  height: fit-content;
  width: fit-content;

  border: none;
  background: none;
  cursor: pointer;
}

.textarea {
  width: auto;
  min-height: 120px;
  padding: 12px 16px;

  border: 1px solid $neutral_300;
  border-radius: 4px;
  background-color: $base_white;
  outline: none;
  resize: none;
  overflow: hidden;

  @extend %font_body_large;
  color: $neutral_600;
}

.editing_container {
  position: relative;
  padding: 16px;
  padding-bottom: 82px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 2px solid $primary_800;
  border-radius: 4px;
}

.editing_toolbar_pane {
  padding: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  background: $neutral_100;
  border-radius: 4px;

  & :global(._toolbarGroupOfGroups_uazmk_217) {
    display: flex;
    gap: 8px;
  }

  button {
    padding: 0;

    border: none;
    background: none;

    cursor: pointer;
  }
}

.editing_toolbar_side {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.editing_editor {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.editing_editor_content {
  @extend %font_body_large;
  color: $neutral_600;

  outline: none !important;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.editing_actions {
  position: absolute;
  bottom: 16px;

  &:dir(ltr) {
    right: 16px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:dir(rtl) {
    left: 16px;
  }
}

:global(.mdxeditor-popup-container) > [data-radix-popper-content-wrapper] {
  padding: 4px 8px;

  border-radius: 4px;
  border: 1px solid $neutral_600;

  background-color: $neutral_50;
  color: $neutral_600;
  @extend %font_body_large;
}