/** @format */

@import 'src/styleConstants/colors';
@import 'src/styleConstants/typography';
@import 'src/styleConstants/variables';

.main_loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.main {
  @include mobile {
    padding: 8px;
  }
}

.learning_materials_editor_page {
  max-width: 920px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 36px;
}

.header {
  display: flex;
  align-content: center;
  justify-content: space-between;

  flex-wrap: wrap;
}

.title {
  margin: 0;
  padding: 0;

  @extend %font_headline_large;
  color: $neutral_900;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include mobile {
    gap: 16px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 16px;
}

.main_message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;

  max-width: 512px;

  .message_title {
    margin: 0;
    padding: 0;

    text-align: center;
    color: $neutral_900;
    @extend %font_display_large;
  }

  .message_description {
    margin: 0;
    padding: 0;

    text-align: center;
    color: $neutral_900;
    @extend %font_title_large;
  }
}