/** @format */

@import "src/styleConstants/colors";

.loader {
  @keyframes spin-loader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  box-sizing: border-box;

  border-style: solid;
  border-color: $primary_700;

  &[data-inverted="true"] {
    border-color: $base_white;
  }

  border-top-color: transparent !important;
  border-radius: 100%;
  animation: spin-loader 0.7s infinite linear;
}
