
$animationFunction: cubic-bezier(.694,.0482,.335,1);

@mixin mobile () {
  @media screen and (pointer: coarse) and (max-width: 500px)  {
    @content;
  }
}

@mixin desktop () {
  @media screen and (not (pointer: coarse))  {
    @content;
  }
  @media screen and (not (max-width: 500px))  {
    @content;
  }
}