/** @format */

main.main {
  position: absolute;
  min-width: 100%;
  min-height: 100%;

  padding-top: 96px;
  padding-bottom: min(max(11.16vh, 0px), 96px);
  box-sizing: border-box;
}