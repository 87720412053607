/** @format */

%shadow-inner {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06) inset;
}

%shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

%shadow {
  box-shadow:
          0 1px 2px 0 rgba(0, 0, 0, 0.06),
          0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

%shadow-md {
  box-shadow:
          0 2px 4px -1px rgba(0, 0, 0, 0.06),
          0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

%shadow-lg {
  box-shadow:
          0 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

%shadow-xl {
  box-shadow:
          0 10px 10px -5px rgba(0, 0, 0, 0.04),
          0px 20px 25px -5px rgba(0, 0, 0, 0.1);
}

%shadow-xxl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
