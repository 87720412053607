/** @format */

@import "src/styleConstants/typography";
@import "src/styleConstants/colors";
@import "src/styleConstants/variables";

.content {
  @include desktop {
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    max-width: 565px;
    padding: 0 10%;

    &:dir(ltr) {
      left: 0;
    }

    &:dir(rtl) {
      right: 0;
    }

    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  @include mobile {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 40px
  }
}

.title {
  margin: 0;
  padding: 0;

  @include desktop {

    color: $neutral_900;
    @include font_display_large;
  }

  @include mobile {



    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;

    text-align: center;
  }
}

.description {
  margin: 0;
  padding: 0;

  color: $neutral_900;
  @extend %font_homepage_description;

  @include mobile {
    text-align: center;
  }
}

.actions {
  display: flex;
  gap: 24px;

  @include mobile {
    flex-direction: column;
  }
}


.adornment_image {
  position: fixed;
  //bottom: -80px;
  bottom: 0;

  &:dir(ltr) {
    right: calc(50% - 808px);
  }

  &:dir(rtl) {
    left: calc(50% - 808px);
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.how_it_works_link {
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 12px 24px;

  text-decoration: none;

  color: $primary_600;
  @extend %font_title_medium;
}