/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/typography";
@import "src/styleConstants/variables";

.main_message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;

  max-width: 512px;

  .message_title {
    margin: 0;
    padding: 0;

    text-align: center;
    color: $neutral_900;
    @extend %font_display_large;
  }

  .message_description {
    margin: 0;
    padding: 0;

    text-align: center;
    color: $neutral_900;
    @extend %font_title_large;
  }

  .message_actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @include mobile {
      flex-direction: column;
      align-items: stretch;
    }
  }
}