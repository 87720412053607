/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/typography";

.pending_page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pending_container {
  margin: 24px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;

  max-width: 512px;
}

.title {
  margin: 0;
  padding: 0;

  text-align: center;
  color: $neutral_900;
  @extend %font_display_large;
}

.description {
  margin: 0;
  padding: 0;

  text-align: center;
  color: $neutral_900;
  @extend %font_title_large;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}