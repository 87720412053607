
@import "src/styleConstants/variables";

$animationDuration: 0.4s;

.animation_container {
  display: flex;

  @keyframes container-animation {
    from {
      width: var(--current-width);
      height: var(--current-height);
    }
    to {
      width: var(--next-width);
      height: var(--next-height);
    }
  }

  &[data-is-animation-running="true"] {
    position: relative;
    width: var(--next-width);
    height: var(--next-height);

    display: flex;
    align-items: start;
    justify-content: center;

    animation: container-animation $animationDuration $animationFunction;
  }

  &[data-is-animation-running="false"] {
    max-width: 100%;
    //width: max-content;
    //height: max-content;
  }
}

.animation_frame {
  width: max-content;
  max-width: 100%;
  height: max-content;

  &[data-is-animation-running="true"] {
    position: fixed;
    top: var(--top);
    animation-duration: $animationDuration;
    animation-timing-function: $animationFunction;
  }
}


.current_animation_frame {
  @extend .animation_frame;

  &[data-is-animation-running="true"] {
    @keyframes current-slide-to-left {
      from {
        transform: translateX(0%);
        opacity: 1;
      }

      to {
        transform: translateX(-100%);
        opacity: 0;
      }
    }

    @keyframes current-slide-to-right {
      from {
        transform: translateX(0%);
        opacity: 1;
      }

      to {
        transform: translateX(100%);
        opacity: 0;
      }
    }

    &[data-animation-direction="forward"] {
      &:dir(ltr) {
        animation-name: current-slide-to-left;
        transform: translateX(-100%);
      }
      &:dir(rtl) {
        animation-name: current-slide-to-right;
        transform: translateX(100%);
      }
    }

    &[data-animation-direction="backward"] {
      &:dir(ltr) {
        animation-name: current-slide-to-right;
        transform: translateX(100%);
      }
      &:dir(rtl) {
        animation-name: current-slide-to-left;
        transform: translateX(-100%);
      }
    }

    opacity: 0;
  }
}

.next_animation_frame {
  position: fixed;
  top: var(--top);
  @extend .animation_frame;
  opacity: 0;

  &[data-is-animation-running="true"] {
    @keyframes next-slide-from-right {
      from {
        transform: translateX(100%);
        opacity: 0;
      }

      to {
        transform: translateX(0%);
        opacity: 1;
      }
    }

    @keyframes next-slide-from-left {
      from {
        transform: translateX(-100%);
        opacity: 0;
      }

      to {
        transform: translateX(0%);
        opacity: 1;
      }
    }

    &[data-animation-direction="forward"] {
      &:dir(ltr) {
        animation-name: next-slide-from-right;
      }
      &:dir(rtl) {
        animation-name: next-slide-from-left;
      }
    }

    &[data-animation-direction="backward"] {
      &:dir(ltr) {
        animation-name: next-slide-from-left;
      }
      &:dir(rtl) {
        animation-name: next-slide-from-right;
      }

    }

    opacity: 1;
    transform: translateX(0);
    animation: next-slide $animationDuration $animationFunction;
  }
}