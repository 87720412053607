/** @format */

@import "../../styleConstants/colors";

.background {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: $primary_50;

  z-index: -1;

  background-image: url("./fx1.svg"), url("./fx2.svg"), url("./fx3.svg");
  background-position: left bottom, right top, 6% 20%;
  background-repeat: no-repeat;
}