/** @format */

html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}