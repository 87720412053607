/** @format */

@import "../createStoryPage.module";

.zero_step_form {
  @extend .form;

  max-width: 534px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
