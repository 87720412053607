/** @format */

@import "src/styleConstants/colors";
@import "src/styleConstants/typography";

.file_field {
  position: relative;
  width: 72px;
  height: 72px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid $primary_400;
  border-radius: 4px;
  background: none;

  cursor: pointer;

  &:after {
    content: "+";
    color: $primary_400;
    @extend %font_title_large;
  }
}

.filled_file_field {
  width: fit-content;
  padding: 8px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  background-color: $primary_200;
  border-radius: 40px;
}

.label {
  margin: 0;
  padding: 0;

  color: $neutral_900;
  @extend %font_label_medium;
}

.close_button {
  width: 24px;
  height: 24px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: $primary_300;
  border-radius: 100%;

  cursor: pointer;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;

  display: block;

  opacity: 0;
  cursor: pointer;
}