/** @format */

@import "src/styleConstants/typography";
@import "src/styleConstants/colors";

.checkbox_container {
  display: flex;
  align-items: center;
  gap: 12px;

  @extend %font_title_medium;
  color: $neutral_600;
}

.checkbox {
  position: relative;

  width: 20px;
  height: 20px;
  margin: 0;

  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;

    width: 20px;
    height: 20px;

    background: url("./checkboxUnchecked.svg");
  }

  &:checked::before {
    background: url("./checkboxChecked.svg");
  }
}