/** @format */

@import "../createStoryPage.module";

.activity_form {
  @extend .form;
  max-width: 732px;
}

.activity_checkbox_block {
  padding: 24px;
  width: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.activity_checkbox_block_text {
  margin: 0;
  padding: 0;

  @extend %font_title_large;
  text-align: center;
  //color: $neutral_600;
}

.activity_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}